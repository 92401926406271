<template>
	<div class="">
		<div v-if="loading">
			<div class="center text-center p-4">
				<span class="fe fe-spinner">Loading..</span>
			</div>
		</div>
		<div v-else>
			<div class="row">
				<div class="col-12 col-xl-7">
					<div class="card">
						<div class="card-header justify-content-between">
							<h4 class="card-header-title">Marshal Information</h4>
							<div class="dropdown"></div>
						</div>
						<div class="card-body p-2">
							<div class="list-group list-group-flush mb-4">
								<div class="list-group-item">
									<div class="row align-items-center">
										<div class="col">
											<span class="text-muted text-uppercase">Name</span>
										</div>
										<div class="col-auto">
											<span class="">{{
												`${marshal.fname} ${marshal.lname}`
											}}</span>
										</div>
									</div>
								</div>
								<div class="list-group-item">
									<div class="row align-items-center">
										<div class="col">
											<span class="text-muted text-uppercase">Phone Number</span>
										</div>
										<div class="col-auto">
											<span class="">{{ marshal.phone }}</span>
										</div>
									</div>
								</div>
								<div class="list-group-item">
									<div class="row align-items-center">
										<div class="col">
											<span class="text-muted text-uppercase">Email Address</span>
										</div>
										<div class="col-auto">
											<span class="">{{ marshal.email }}</span>
										</div>
									</div>
								</div>
								<div class="list-group-item">
									<div class="row align-items-center">
										<div class="col">
											<span class="text-muted text-uppercase">Gender</span>
										</div>
										<div class="col-auto">
											<span class="" v-if="marshal.gender">{{
												marshal.gender | titleCase
											}}</span>
										</div>
									</div>
								</div>
								<div class="list-group-item">
									<div class="row align-items-center">
										<div class="col">
											<span class="text-muted text-uppercase">Date Joined</span>
										</div>
										<div class="col-auto">
											{{ marshal.created_at | date('MMM do, yyyy') }}
										</div>
									</div>
								</div>
								<div class="list-group-item">
									<div class="row align-items-center">
										<div class="col">
											<span class="text-muted text-uppercase">Route Assigned</span>

										</div>
									</div>
								</div>

								<div class="list-group-item">
									<div class="row align-items-center">
										<div class="col">
											<span class="text-muted text-uppercase">Ratings</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-12 col-xl-5">
					<div class="card">
						<div class="card-header justify-content-between">
							<h4 class="card-header-title">Pick up and Drop Off Locations</h4>
						</div>
						<div class="p-4 m-2">
							<small class="route-code p-2 font-weight-bolder rounded-pill mx-2">IKR 104</small>
							<small class="route-code p-2 font-weight-bolder rounded-pill mx-2">5:00AM</small>
						</div>
						<div class="potty" v-for="(route, index) in marshalRoutes" :key="index">
							<RouteDescription :pickup="route.pickup" :destination="route.destination" />
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Swal from 'sweetalert2'
import RouteDescription from '@/components/modules/routes/RouteDescription'

export default {
  components: {
    RouteDescription
  },
  props: {
    marshalId: {
      required: true,
      type: [Number, String]
    }
  },
  data() {
    return {
      marshalRoutes: [
        {
          route_code: 'IKR104',
          itenerary: '10:00 AM',
          pickup: 'Sandfill Bus stop, Ozumba Mbadiwe, Victoria Island, Lagos State',
          destination: 'Shuttlers, Jasper Ike, Maroko, Lagos State'
        }
      ],
      marshal: '',
      processingExecution: false,
      loading: true,
      errorLoading: false,
      currentCreditSystem: null,
      needCreditSystem: false,
      error: null,
      trips: [],
      vehicles: []
    }
  },
  created() {
    this.init()
  },
  computed: {
    currentUser() {
      return this.$store.getters.currentUser
    },
    isMarshalActive() {
      if (!this.marshal) {
        return false
      }

      return !!parseInt(this.marshal.active)
    },
    isMarshalBlocked() {
      return !!this.marshal.is_blocked
    },
    marshalDevice() {
      return this.marshal &&
        Object.prototype.hasOwnProperty.call(this.marshal, 'device')
        ? this.marshal.device?.device_brand
        : 'Not Available'
    }
  },
  methods: {
    async init() {
      this.loading = true
      this.errorLoading = false

      this.fetchMarshal()
        .catch(() => (this.errorLoading = true))
        .finally(() => (this.loading = false))
    },
    fetchMarshal() {
      return this.axios
        .get(`/v1/bus-marshals/${this.marshalId}`)
        .then((res) => {
          this.marshal = res.data
          
        })
    },
    //
    fetchVehicles() {
      return this.axios.get('/v1/vehicles').then((res) => {
        this.vehicles = res.data
      })
    },
    //
    async unblockMarshal() {
      const isBlockedAction = this.isMarshalBlocked
      await this.$swal({
        icon: 'question',
        title: 'Please Confirm',
        text: `Are you sure you want to ${this.isMarshalBlocked ? 'unblock' : 'block'
          } this user?`,
        showConfirmButton: true,
        showCancelButton: true,
        preConfirm: () => {
          return this.axios
            .patch(`/v1/bus-marshals/${this.marshalId}/blocked`, {
              isBlocked: !this.isMarshalBlocked
            })
            .then((res) => {
              this.user = res.data
              return res.data
            })
            .catch((error) => {
              const msg =
                error.response && error.response.data
                  ? error.response.data.message
                  : 'An error occured, please try again.'
              this.$swal().showValidationMessage(msg)
            })
        },
        allowOutsideClick: () => !Swal.isLoading()
      }).then((result) => {
        if (result.isConfirmed) {
          this.$swal({
            icon: 'success',
            title: 'Success',
            text: `user has been ${isBlockedAction ? 'unblocked' : 'blocked'
              } successfully`,
            showCloseButton: true
          })
        }
      })
    },
    processRouteAdded() {
      this.fetchMarshalTrips()
    },
    processVehicleAssigned(vehicleId) {
      this.marshal.vehicle_id = vehicleId
    },
    processVehicleUpdated(vehicle) {
      this.marshal.vehicle = { ...vehicle }
    }
  }
}
</script>

<style lang="scss" scoped>
@use "src/assets/scss/b-table";

.route-code {
  background: #D3DCE6 !important;
  color: black;
  font-size: 12px;

}

.potty {
  padding-left: 35px;
}
</style>
